import { get, put, post, destroy } from "./_utils";

export const getSubmissions = (formId, data = {}) => {
  return get(`/forms/${formId}/submissions.json`, data);
};

export const updateSubmission = (formId, submissionId, data) => {
  return put(`/forms/${formId}/submissions/${submissionId}.json`, data);
};

export const markSubmissionSpam = (formId, submissionId) => {
  return post(`/forms/${formId}/submissions/${submissionId}/spam.json`);
};

export const markSubmissionHam = (formId, submissionId) => {
  return post(`/forms/${formId}/submissions/${submissionId}/ham.json`);
};

export const toggleSubmissionStar = (formId, submissionId, data) => {
  let postData = { is_starred: data };
  return post(
    `/forms/${formId}/submissions/${submissionId}/toggle_star.json`,
    postData,
  );
};

export const deleteSubmission = (formId, submissionId) => {
  return destroy(`/forms/${formId}/submissions/${submissionId}.json`);
};

export const markSubmissionsRead = (formId, data) => {
  let postData = { submission_ids: data };
  return post(`/forms/${formId}/submissions/mark_read.json`, postData);
};

export const getPresignedURLForSubmission = (filesInfo, uuid, sessionId) => {
  let postData = { filesInfo, sessionId };
  return post(`/forms/${uuid}/submissions/presign.json`, postData);
};

export const markAllSubmissionRead = (formId) => {
  return post(`/forms/${formId}/submissions/mark_all_read.json`);
};

export const markBulkSpam = (formId, data) => {
  let postData = { submission_ids: data };
  return post(`/forms/${formId}/submissions/mark_bulk_spam.json`, postData);
};

export const markBulkHam = (formId, data) => {
  let postData = { submission_ids: data };
  return post(`/forms/${formId}/submissions/mark_bulk_ham.json`, postData);
};

export const bulkDestroy = (formId, data) => {
  let postData = { submission_ids: data };
  return post(`/forms/${formId}/submissions/bulk_destroy.json`, postData);
};
